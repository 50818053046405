// Necessary if using App Router to ensure this file runs on the client
'use client';

import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';

if (
  process.env.NEXT_PUBLIC_DEPLOY_ENV === 'production' &&
  process.env.NEXT_PUBLIC_DD_RUM_CLIENT_TOKEN &&
  process.env.NEXT_PUBLIC_DD_RUM_APPLICATION_ID
) {
  datadogRum.init({
    applicationId: process.env.NEXT_PUBLIC_DD_RUM_APPLICATION_ID!,
    clientToken: process.env.NEXT_PUBLIC_DD_RUM_CLIENT_TOKEN!,
    site: 'datadoghq.com',
    service: 'web-app',
    env: process.env.NEXT_PUBLIC_DEPLOY_ENV,
    version: process.env.NEXT_PUBLIC_APP_VERSION,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    trackUserInteractions: false,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
    allowedTracingUrls: ['https://app.claritypediatrics.com'],
    beforeSend: (event) => {
      if (event.type === 'error' && event.error.message.includes("Can't find variable: fbq")) {
        return false;
      }

      if (event.type === 'error' && event.error.message.includes('fbq is not defined')) {
        return false;
      }

      if (
        event.type === 'error' &&
        event.error.message.includes('An error occurred in the Server Components render')
      ) {
        return false;
      }

      if (event.type === 'error' && event.error.message.includes('Fetch error GET')) {
        return false;
      }

      if (
        event.type === 'error' &&
        event.error.message.includes('Uncaught "Object Not Found Matching')
      ) {
        return false;
      }

      return true;
    },
  });
}

if (
  (process.env.NEXT_PUBLIC_DEPLOY_ENV === 'production' ||
    process.env.NEXT_PUBLIC_DEPLOY_ENV === 'staging') &&
  process.env.NEXT_PUBLIC_DD_LOG_CLIENT_TOKEN
) {
  datadogLogs.init({
    clientToken: process.env.NEXT_PUBLIC_DD_LOG_CLIENT_TOKEN!,
    site: 'datadoghq.com',
    service: 'web-app',
    env: process.env.NEXT_PUBLIC_DEPLOY_ENV,
    version: process.env.NEXT_PUBLIC_APP_VERSION,
    forwardErrorsToLogs: true,
    sessionSampleRate: 100,
    beforeSend: (log) => {
      if (log.message.includes("Can't find variable: fbq")) {
        return false;
      }

      if (log.message.includes('fbq is not defined')) {
        return false;
      }

      if (log.message.includes('An error occurred in the Server Components render')) {
        return false;
      }

      if (log.message.includes('Fetch error GET')) {
        return false;
      }

      if (log.message.includes('Uncaught "Object Not Found Matching')) {
        return false;
      }

      return true;
    },
  });
}

export default function DatadogInit() {
  // Render nothing - this component is only included so that the init code
  // above will run client-side
  return null;
}
