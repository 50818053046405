'use client';

import { JOTFORM_URL_PREFIX } from '@cp/shared/lib/constants';
import Script from 'next/script';

export const FormsScript = () => {
  const handler = () => {
    window.jotformEmbedHandler("iframe[id='JotFormIFrame-clarity'", `${JOTFORM_URL_PREFIX}/form/`);
  };

  return (
    <Script src={`${JOTFORM_URL_PREFIX}/s/umd/latest/for-form-embed-handler.js`} onLoad={handler} />
  );
};

export default FormsScript;
